import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../../components/Layout/Layout"
import Seo from "../../components/Layout/Seo"

import { HeroSimple } from '../../components/Components/Heroes/HeroSimple';
import Navbar from '../../components/Layout/Navbar';
import { LandingGrid } from '../../components/Components/Content/LandingGrid';
import { PageTitle } from "../../components/Components/Headings/PageTitle"
import { MainGrid } from '../../components/Components/Content/MainGrid';
import { ColumnGrid } from '../../components/Components/Content/ColumnGrid';



const Video = ({ data }) => {

  console.log(data);

  const projects = data.projects.nodes;

  return (
    <Layout>
      <Seo title="Kinzica Studio" />
      <div className="sm:w-11/12 w-full mx-auto">

        <PageTitle id="videos" title="Videos" subtitle="Check our works!" />
        <MainGrid id={"all-videos"} projects={projects} />
      </div>
    </Layout >
  )
}

export default Video;


export const query = graphql`
query VideosWorks {
  projects: allMarkdownRemark(filter: {frontmatter: {tag: {eq: "video"}}}) {
    nodes {
      frontmatter {
        slug
        title
        tag
        thumb {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      id
    }
  }
}
`;
